import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPagev2 from "../src/Pages/LandingPagev2";
import LoadingPagev1 from "../src/Pages/LoadingPagev1";
import DataState from "ContextAPI/DataContext";
import DashboardView from "Pages/Dashboardv2/DashboardPage";

import PrivacyPolicy from "Pages/PrivacyPolicy";

const ExtensionLandingPage = React.lazy(() =>
  import("../src/Pages/ExtensionLandingPage")
);
const HomeProcessing = React.lazy(() => import("../src/Pages/HomeProcessing"));
const ProjectRoutes = () => {
  return (
    <DataState>
      <React.Suspense fallback={<>Loading...</>}>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPagev2 />} />

            <Route path="/email-warmup" element={<LoadingPagev1 />} />


            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            <Route path="/dashboard" element={<DashboardView />} />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          </Routes>
        </Router>
      </React.Suspense>
    </DataState>
  );
};
export default ProjectRoutes;
